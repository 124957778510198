import {
  PropertyInfo,
  TaskStatus,
  TaskStatusChangeAction,
  SearchableTask,
} from '@super-software-inc/foundation'
import {
  DropdownTriggerButton,
  FlexRow,
  MultilevelDropdown,
  MultilevelItem,
  MultilevelNoResults,
} from 'components/lib'
import { MultilevelHeader, Positions } from 'components/lib/MultilevelDropdown'
import { DocumentData } from 'firebase/firestore'
import React, { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import Status from './Status'

const NullButton = styled.button<{ buttonLike?: boolean }>`
  background: ${props =>
    props.buttonLike ? props.theme.colors.bg200 : 'transparent'};
  margin: 0;
  border: none;
  border-radius: 4px;
  color: ${props => props.theme.colors.text0};
  font-size: 0.8rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: ${props => (props.buttonLike ? 'pointer' : 'initial')};
  transition: all ${props => props.theme.transitions.short} ease;
  &:hover {
    background: ${props =>
      props.buttonLike ? props.theme.colors.bg300 : 'transparent'};
  }

  &:disabled {
    color: ${props => props.theme.colors.text300};
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
    }
  }
`

export type StatusSelectorOnChange = ({
  task,
  newStatus,
  action,
}: {
  task: SearchableTask
  newStatus: TaskStatus
  action: TaskStatusChangeAction
}) => void

interface StatusOption {
  id: number
  value: TaskStatus
  text: string
  verb: string
  notify: boolean
}

export const statusOptions: StatusOption[] = [
  {
    id: 0,
    value: TaskStatus.OPEN,
    text: 'Open',
    verb: 'open',
    notify: false,
  },
  {
    id: 1,
    value: TaskStatus.PENDING,
    text: 'Pending',
    verb: 'pending',
    notify: false,
  },
  {
    id: 2,
    value: TaskStatus.ON_HOLD,
    text: 'On hold',
    verb: 'on hold',
    notify: false,
  },
  {
    id: 3,
    value: TaskStatus.CLOSED,
    text: 'Closed',
    verb: 'close',
    notify: false,
  },
  {
    id: 4,
    value: TaskStatus.CLOSED,
    text: 'Closed',
    verb: 'close',
    notify: true,
  },
  {
    id: 5,
    value: TaskStatus.CANCELLED,
    text: 'Canceled',
    verb: 'cancel',
    notify: false,
  },
]

const StatusSelector = ({
  type,
  buttonLike,
  value,
  showLabel,
  onChange,
  isSimple,
  task,
  className,
  ...rest
}: {
  type?: 'meeting' | 'task' | null | undefined
  value: TaskStatus
  showLabel?: boolean
  buttonLike?: boolean
  onChange: StatusSelectorOnChange
  rest?: any
  style?: any
  isSimple?: boolean
  task?: DocumentData
  className?: string
}) => {
  const [searchValue, setSearchValue] = useState('')
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const acl = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo.find(
        (p: PropertyInfo) => p.associationId === task?.associationId,
      )?.acl,

    [authenticatedUser.selectedContact, task?.associationId],
  )

  const isDisabled = useMemo(() => {
    const potentialDisabledStatuses = [
      TaskStatus.OPEN,
      TaskStatus.PENDING,
      TaskStatus.ON_HOLD,
    ]

    const canClose =
      (task && !task.associationId) ||
      acl?.tasks.edit ||
      (task?.createdBy &&
        task?.createdBy?.contactId === authenticatedUser.selectedContact.id) ||
      (task?.assignee &&
        task?.assignee?.contactId === authenticatedUser.selectedContact.id)

    return potentialDisabledStatuses.includes(value) && !canClose
  }, [task, acl?.tasks.edit, authenticatedUser.selectedContact.id, value])

  return (
    <span
      style={{ position: 'relative' }}
      // Stop event propogation.
      onClick={evt => {
        evt.stopPropagation()
        evt.preventDefault()
      }}
      className={className || ''}
    >
      <MultilevelDropdown
        position={Positions.Right}
        trigger={
          buttonLike ? (
            <DropdownTriggerButton hasValue={!!value} {...rest} />
          ) : (
            <NullButton {...rest} />
          )
        }
        title={
          <FlexRow>
            <Status type={type} status={value} isDisabled={isDisabled} />
            {showLabel && (
              <span style={{ textTransform: 'capitalize', marginLeft: 8 }}>
                {value}
              </span>
            )}
          </FlexRow>
        }
        isDisabled={isDisabled}
      >
        {isSimple && (
          <MultilevelHeader
            onChange={setSearchValue}
            isDisabled={false}
            value={searchValue}
            placeholder="Change status"
            clearValue={() => setSearchValue('')}
          />
        )}
        {statusOptions.map(option => (
          <MultilevelItem
            key={option.id}
            onClick={() => {
              if (value === option.value) return
              onChange({
                task: task as SearchableTask,
                newStatus: option.value,
                action: option.notify
                  ? TaskStatusChangeAction.NOTIFY
                  : TaskStatusChangeAction.SILENT,
              })
            }}
          >
            <FlexRow align="center">
              <Status
                status={option.value}
                style={{ marginRight: 8 }}
                isDisabled={isDisabled}
              />
              {/* TODO: what is isSimple? it looks identical to what's above */}
              {isSimple && (
                <Status
                  status={option.value}
                  style={{ marginRight: 8 }}
                  isDisabled={isDisabled}
                />
              )}
              {option.text}
              {option.notify && (
                <span
                  style={{
                    fontSize: '12px',
                    color: '#627088',
                    marginLeft: 6,
                  }}
                >
                  (Notify)
                </span>
              )}
            </FlexRow>
          </MultilevelItem>
        ))}
        {statusOptions.length === 0 && <MultilevelNoResults />}
      </MultilevelDropdown>
    </span>
  )
}

export default StatusSelector
